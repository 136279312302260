<script>
export default {
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        handleKeyDown(event) {
            if (event.key === 'Escape') {
                this.close();
            }
        },
        close() {
            this.$emit("close");
        }
    }
};
</script>

<template>
    <div>
        <div
            class="flex flex-col px-4 pt-5 pb-4 space-y-2 bg-white sm:p-4 sm:pb-1"
        >
            <img
                class="w-40 m-0"
                src="@/core/assets/images/logo_sc_mobi_novo.png"
                alt="SCmobi"
            />
            <p class="text-sm font-medium">
                Olá usuário(a),
            </p>
            <p class="text-sm font-medium">
                Informamos que, conforme a <strong>Instrução Normativa SIE N° 02/2025, de 31 de março de 2025</strong>, foram estabelecidas novas diretrizes para a emissão de licenças de fretamento. A partir de 01 de maio de 2025, para a solicitação da licença, o operador deverá apresentar e informar os seguintes documentos e dados:
            </p>
            <ul class="text-sm font-medium list-disc list-inside space-y-1">
                <li><strong>Para fretamentos eventuais:</strong></li>
                <ul class="ml-4 space-y-1">
                    <li>Carteira de identidade, para passageiros com nacionalidade de países do MERCOSUL, identificando país e data de nascimento.</li>
                    <li>Certidão de nascimento, em caso de ausência dos documentos descritos, identificando estado, município e data de nascimento.</li>
                    <li>Nome completo e CPF do(s) motorista(s).</li>
                    <li>Documento fiscal de prestação do serviço (chave de acesso do CT-e OS) e seu respectivo valor.</li>
                    <li>Quilometragem total a percorrer.</li>
                </ul>
            </ul>
            <p class="text-sm font-medium">
                <strong>OBS:</strong> Outras alterações citadas na Instrução Normativa já estão vigorando no sistema.
            </p>
            <p class="text-sm font-medium">
                Ressaltamos a importância de atender a essas exigências para garantir a regularidade dos processos.
                Para mais detalhes, consulte a Instrução Normativa completa no link: 
                <a href="https://www.sie.sc.gov.br/webdocs/sie/legislacao/estadual/leis/INSTRU%C3%87%C3%83O_NORMATIVA_SIE_02_2025.pdf" class="text-blue-600 underline" target="_blank">Clique aqui para acessar</a>.
            </p>
            <p class="text-sm font-medium">
                Qualquer dúvida, entre em contato com nosso suporte via chamado ou por telefone <strong>(48) 3664-9229</strong>.
            </p>
        </div>
        <div
            class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-col sm:place-items-center"
        >
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal uppercase transition duration-150 ease-in-out rounded-md shadow-sm btn-blue focus:outline-none sm:leading-tight"
                >
                    Fechar
                </button>
            </span>
        </div>
    </div>
</template>
