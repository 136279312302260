<script>
import Header from "@/core/components/Header";
import Sidebar from "@/core/components/Sidebar";
import Footer from "@/core/components/Footer";
import Modal from "@/core/components/NewModal";
import RegistrationWarning from "@/domains/auth/components/RegistrationWarning";
import CircularWarning from "@/domains/auth/components/CircularWarning";
import {
    confirmReregistration,
    confirmCircularInfo
} from "@/domains/re-registration/services/reregistration";
import RegistryService from "@/core/services/RegistryService";

export default {
    components: {
        CircularWarning,
        Header,
        Sidebar,
        Footer,
        Modal,
        RegistrationWarning
    },
    data() { // Adicione o data() para inicializar o serviço
        return {
            registryService: new RegistryService()
        };
    },
    mounted() {
        if (this.$store.state.auth.link) {
            const organizationId = this.$store.state.auth.link.organization_id;
            confirmReregistration(
                this.$store.state.auth.link.organization_id
            ).catch(() => {
                this.showRegistrationWarning();
            });
            confirmCircularInfo(organizationId)
                .then(() => {
                    // Verifica o type_id antes de exibir o aviso
                    return this.registryService.getDeterInfoOrganization(organizationId);
                })
                .then(response => {
                    const typeId = response[0]?.deter_registry?.type_id;
                    if (typeId === 1 || typeId === 2 || typeId === 3) {
                        this.showCircularWarning();
                    }
                });
        }
    },
    methods: {
        toggleSidebar() {
            this.$refs.sidebar.toggleOnMobile();
        },
        showRegistrationWarning() {
            this.$refs.registrationWarning.show();
        },
        hideRegistrationWarning() {
            this.$refs.registrationWarning.hide();
        },
        showCircularWarning() {
            this.$refs.circularWarning.show();
        },
        hideCircularWarning() {
            this.$refs.circularWarning.hide();
        }
    }
};
</script>

<template>
    <div class="flex flex-col min-h-screen">
        <Header @toggleSidebar="toggleSidebar" />
        <div class="flex flex-grow w-full" :key="this.$route.meta.module">
            <portal-target name="main-modal" multiple />
            <Sidebar
                v-if="!this.$route.meta.hideSidebar"
                id="sidebar"
                ref="sidebar"
            />
            <Modal
                ref="registrationWarning"
                to="main-modal"
                contentClass="sm:max-w-4xl"
                :closeOnClickOutside="false"
            >
                <RegistrationWarning @close="hideRegistrationWarning" />
            </Modal>
            <Modal
                ref="circularWarning"
                to="main-modal"
                contentClass="sm:max-w-4xl"
                :closeOnClickOutside="false"
            >
                <CircularWarning @close="hideCircularWarning" />
            </Modal>
            <router-view class="flex-grow" />
        </div>
        <Footer />
    </div>
</template>
